import { audioPlayClick, screenWidth, topLoadingToggle, } from "@/core/helpers/custom";
import { debounce } from "lodash";
import { computed, defineComponent, onMounted, ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import { getlistEditCategories } from "@/api/edit/CategoryApi";
import CategoryFormModal from "@/components/modals/forms/CategoryFormModal.vue";
export default defineComponent({
    name: "BaseHorizontalMenuEdit",
    components: { CategoryFormModal: CategoryFormModal },
    props: {
        isRoutable: {
            type: Boolean,
            default: true,
        }
    },
    setup: function () {
        var categories = ref([]);
        var scrollmenu = ref();
        var store = useStore();
        var router = useRouter();
        var getBrandData = function () {
            topLoadingToggle(true);
            getlistEditCategories({ limit: 100 })
                .then(function (_a) {
                var data = _a.data;
                categories.value = data.data;
            })
                .catch();
            topLoadingToggle(false);
        };
        var route = useRoute();
        var isEditing = ref(false);
        var isModalOpen = ref(false);
        var selectCategory = ref(null);
        var menuScroll = function (e) {
            if (e.deltaY > 0) {
                e.currentTarget.scrollLeft += 32;
            }
            else {
                e.currentTarget.scrollLeft -= 32;
            }
        };
        var openModal = function (id) {
            if (isEditing.value) {
                selectCategory.value = id;
                isModalOpen.value = true;
            }
        };
        var closeModal = function () {
            getBrandData();
            isModalOpen.value = false;
        };
        var getDefaultMenuPosition = computed(function () {
            var _a;
            return (_a = store.getters.getStoreData) === null || _a === void 0 ? void 0 : _a.default_menu_position;
        });
        var all = function () {
            audioPlayClick("Mouse");
            if (scrollmenu.value) {
                scrollmenu.value.scrollTo({
                    left: 0,
                    behavior: "smooth",
                });
            }
        };
        var currentScreenWidth = ref(0);
        var resizeScreen = debounce(function () {
            currentScreenWidth.value = screenWidth();
        }, 100);
        var gotoOtherCategory = function () {
            audioPlayClick("Mouse");
        };
        onMounted(function () {
            getBrandData();
            resizeScreen();
            window.addEventListener("resize", resizeScreen);
        });
        watch(function () { return route.query.edit; }, function (newValue) {
            isEditing.value = newValue === "true"; // Convert to boolean
        }, { immediate: true } // Runs initially when the component is mounted
        );
        return {
            categories: categories,
            route: route,
            menuScroll: menuScroll,
            scrollmenu: scrollmenu,
            router: router,
            all: all,
            getDefaultMenuPosition: getDefaultMenuPosition,
            gotoOtherCategory: gotoOtherCategory,
            openModal: openModal,
            closeModal: closeModal,
            isModalOpen: isModalOpen,
            isEditing: isEditing,
            selectCategory: selectCategory
        };
    },
});
