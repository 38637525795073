import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, Transition as _Transition, withCtx as _withCtx, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function (n) { return (_pushScopeId("data-v-4c0c9e9e"), n = n(), _popScopeId(), n); };
var _hoisted_1 = { class: "d-flex flex-colunm w-100" };
var _hoisted_2 = {
    id: "kt_content_container",
    style: { "position": "fixed", "width": "100%" }
};
var _hoisted_3 = { class: "sticky-top" };
var _hoisted_4 = {
    class: "d-md-flex container-fluid",
    style: { "background-color": "#fbfbfb" }
};
var _hoisted_5 = { class: "p-xs-0 p-sm-0 first_block flex_10" };
var _hoisted_6 = { class: "main_container flex_2" };
var _hoisted_7 = { style: { "z-index": "9999" } };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    var _component_KTLoader = _resolveComponent("KTLoader");
    var _component_base_edit_nav_bar = _resolveComponent("base-edit-nav-bar");
    var _component_base_horizontal_menu_edit = _resolveComponent("base-horizontal-menu-edit");
    var _component_router_view = _resolveComponent("router-view");
    var _component_base_loading_logo = _resolveComponent("base-loading-logo");
    var _component_KTFooter = _resolveComponent("KTFooter");
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", null, [
            (_ctx.loaderEnabled)
                ? (_openBlock(), _createBlock(_component_KTLoader, {
                    key: 0,
                    logo: _ctx.loaderLogo
                }, null, 8, ["logo"]))
                : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_2, [
                _createElementVNode("div", _hoisted_3, [
                    _createVNode(_component_base_edit_nav_bar, { class: "sticky-top" })
                ]),
                _createElementVNode("div", _hoisted_4, [
                    _createElementVNode("div", _hoisted_5, [
                        _createElementVNode("div", _hoisted_6, [
                            _createVNode(_component_base_horizontal_menu_edit),
                            _createVNode(_component_router_view, { class: "main_div" }),
                            _createElementVNode("div", _hoisted_7, [
                                _createVNode(_Transition, {
                                    name: "fade",
                                    mode: "out-in"
                                }, {
                                    default: _withCtx(function () { return [
                                        (_ctx.store.getters.globalLoading)
                                            ? (_openBlock(), _createBlock(_component_base_loading_logo, { key: 0 }))
                                            : _createCommentVNode("", true)
                                    ]; }),
                                    _: 1
                                })
                            ])
                        ])
                    ])
                ]),
                _createVNode(_component_KTFooter, { class: "fixed-bottom bg-white" })
            ])
        ])
    ]));
}
